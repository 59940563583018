
<template>
    <div class="table-responsive">
                
        <table class="table table-bordered">  
            
            <tbody v-if="! isDocumentApproval">
                <td width="30%">
                    <h4>Certificates Generated:</h4>
                </td>
                <td>
                    <div class="row">
                        <div class="col-sm-5">
                            <form :action="printCertificateUrl" method="POST" target="_blank">
                                <input type="hidden" name="id" :value="application.id" />
                                <input
                                    type="hidden" name="cert_id"
                                    :value="application.certificate.id"
                                />
                                <input
                                    type="hidden" name="admin_id"
                                    :value="user.id"
                                />

                                <input
                                    type="hidden" name="valid_date"
                                    :value="null"
                                />

                                <base-button
                                    size="sm"
                                    type="info"
                                    nativeType="submit"
                                    :disabled="privileges && privileges.view == 'no'"
                                >
                                    <i class="fa fa-print"></i>
                                    {{ getCertificateName(application.certificate) }}
                                </base-button>
                            </form>
                        </div>
                    </div>
                </td>
            </tbody>

            <tbody v-if='showErrorStatus'>
                <td colspan="2">
                    <base-alert :type="errorType">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </td>
            </tbody>

            <tbody>
                <td width="30%">
                    &nbsp;
                </td>
                <td>
                    <base-button 
                        size="lg"
                        type="success" 
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                        @click="approveUserApplication(application.id)"
                        v-if="! showBackButton"
                    >
                        <i class="fa fa-check"></i> 
                        Approve {{ isDocumentApproval ? 'Document' : 'Application' }} 
                    </base-button>

                    <base-button 
                        size="lg"
                        type="danger" 
                        class="right"
                        @click="$emit('hideApprovePage')"
                        v-if="! showBackButton"
                    >
                        <i class="fa fa-times"></i> 
                        Cancel Approval
                    </base-button>

                    <base-button 
                        size="lg"
                        type="danger" 
                        @click="$emit('hideApprovePage')"
                        v-if="showBackButton"
                    >
                        <i class="fa fa-reply"></i> 
                        Back To Application
                    </base-button>
                </td> 
            </tbody>

        </table>

    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert2';// import sweet alert
import moment from 'moment';

export default {
name: 'DiasporaLicenseApproval',
props: ['application', 'privileges', 'loadApplication'],
data() {
  return {
    code: this.$route.params.code,
    id: this.$route.params.id,
    showErrorStatus: false,
    errorType: null,
    errorMsg: null,
    showBackButton: false,
  }
},
computed: {
    ...mapGetters(['user', 'printCertificateUrl']),
    isDocumentApproval() {// check if it is document approval
        return this.application && (this.application.payment_status == 'not-paid') && this.application.has_diaspora_license 
                && ((this.application.has_diaspora_license.status == "submitted") || (this.application.has_diaspora_license.status == "approved"));
    }
},
methods: {
    ...mapActions(['approveDiasporaLicenseApplication']),
    getCertificateName(type) {
        return type.title ? type.title : 'N/A';
    },
    getFullName(profile) {
        let name = profile.first_name+' ';
        name += profile.other_name ? profile.other_name+' ' : '';
        name += profile.last_name;
        return name;
    },
    approveUserApplication(id) {// approve application
        swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Proceed!'
        }).then((result) => {
            if (result.value) {
                return this.makeDiasporaApproveApiCall(id);// make api call
            }
        });
    },
    makeDiasporaApproveApiCall(id) {// approve application
        if(this.application && this.application.has_diaspora_license) {
            let data = { id : id, approved_date : moment().format('YYYY-MM-DD'), status : 'approved', admin_id : this.user.id, 
                        license_id : this.application.has_diaspora_license.id, is_document: this.isDocumentApproval,
                        valid_date : this.application.has_diaspora_license.year +'-12-31' };
            this.preloader();// show loading
            this.approveDiasporaLicenseApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        } else {
            let msg = "invalid application was selected, kindly check and try again...";
            swal.fire('Invalid Application!', msg, 'error');
        }         
    },
    preloader() {
        return  swal.fire({
            title: 'Please Wait...',
            html: 'Trying to fetch data from <b>server</b>',
            // timer: 2000,
            timerProgressBar: true,
            onBeforeOpen: () => {
                swal.showLoading()
            },
        });
    }
}   
};
</script>
<style scoped>
.right {
float: right;
}
</style>
